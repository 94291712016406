.flex-box {
  display: flex;
  width: 100%;
}

.flex-spaces {
  flex: 1;
}

.flex-row {
  width: 100%;
  display: flex;
  flex-direction: row;
}

// HRX-3362: Payroll Management > Company Setting > Pay Item > Expand details will be grey out
// .MuiExpansionPanelDetails-root {
//   background: #e8e8e8;
// }

li.grayList.MuiListItem-root.MuiListItem-gutters {
  border: none;
  background: #e8e8e8;
  box-shadow: none;
  margin: 0;
}

.border-b {
  border-bottom: 1px solid #cecece;
  width: 100%;
  box-shadow: gray;
}
.expand-card {
  padding: 0;
}
.expand-card .MuiExpansionPanelDetails-root {
  padding: 0;
}

.mb-24 {
  margin-bottom: 24px;
}

.span-all-red > span {
  color: red !important;
}